import React, { useRef, useState, useEffect, useContext } from "react"
import { Row, Cell } from "../../Layout/Grid"
import Icon from "../../Misc/Icon"

import { CustomContext } from "../../../context/CustomProvider"
import { graphql, useStaticQuery } from "gatsby"
import ScrollTo from "../../../helpers/ScrollTo"
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
} from "body-scroll-lock"

import PanelVideoLastEditionStyles from "./PanelVideoLastEdition.module.styl"

const PanelVideo = () => {
    const { vimeo } = useStaticQuery(query)

    const videoRef = useRef(null)
    const timelineRef = useRef(null)

    const { setTopScrollTriger } = useContext(CustomContext)

    const [isVideoMuted, setIsVideoMuted] = useState(false)
    const [isVideoPlaying, setIsVideoPlaying] = useState(false)
    const [windowSize, setWindowSize] = useState(false)
    const [isHoveringTimeline, setIsHoveringTimeline] = useState(false)
    const [timeline, setTimeline] = useState({
        ratio: 0,
        left: 0,
    })
    const [currentTimeScale, setCurrentTimeScale] = useState(0)
    const [wantedTimeScale, setWantedTimeScale] = useState(0.3)
    const [posterLoaded, setPosterLoaded] = useState(false)

    const onClickVideoItem = () => {
        if (videoRef.current) {
            switchVideo()
        }
    }

    const onClickVideoSound = () => {
        if (videoRef.current) {
            muteVideo()
        }
    }

    const onTimeUpdate = () => {
        setCurrentTimeScale(videoRef.current.currentTime / vimeo.duration)
    }

    const onClickTimeline = e => {
        seekAndUpdateVideo(e.pageX)
    }

    const switchVideo = () => {
        if (isVideoPlaying) {
            enableBodyScroll()
            videoRef.current.pause()
            setIsVideoPlaying(false)
        } else {
            new ScrollTo(document.body, {
                dist: 0,
            })
            disableBodyScroll()
            videoRef.current.play()
            setIsVideoPlaying(true)
        }
    }

    const muteVideo = () => {
        if (isVideoPlaying) {
            if (!isVideoMuted) {
                videoRef.current.muted = true
                setIsVideoMuted(true)
            } else {
                videoRef.current.muted = false
                setIsVideoMuted(false)
            }
        }
    }

    const onMouseMove = e => {
        setIsHoveringTimeline(true)
        setWantedTimeScale(seekVideo(e.pageX) / vimeo.duration)
    }

    const onMouseOut = () => {
        setIsHoveringTimeline(false)
        setWantedTimeScale(0)
    }

    const seekVideo = pageX => {
        const mouseRelativePosition = pageX - timeline.left

        return mouseRelativePosition * timeline.ratio
    }

    const seekAndUpdateVideo = pageX => {
        videoRef.current.currentTime = seekVideo(pageX)
    }

    const onResize = () => {
        setWindowSize(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener("resize", onResize)
        return () => {
            window.removeEventListener("resize", onResize)
            clearAllBodyScrollLocks()
        }
    }, [])

    useEffect(() => {
        const image = new Image()
        image.onload = () => {
            setPosterLoaded(true)
        }
        image.src = "/video-poster.jpg"
    }, [])

    useEffect(() => {
        if (timelineRef.current && vimeo.duration) {
            const offsetRow =
                parseInt(
                    getComputedStyle(document.body).getPropertyValue(
                        "--grid-spacer"
                    )
                ) * 10

            setTimeline({
                left: timelineRef.current.offsetLeft + offsetRow,
                ratio: vimeo.duration / timelineRef.current.clientWidth,
            })
        }
    }, [timelineRef.current, vimeo.duration, windowSize])

    useEffect(() => {
        if (videoRef.current) {
            setTopScrollTriger(videoRef.current.clientHeight / 2)
        }
    }, [videoRef])

    return (
        <div
            className={`
            ${PanelVideoLastEditionStyles.PanelVideoLastEdition}
        `}
        >
            <div className={PanelVideoLastEditionStyles.PanelInner}>
                <div
                    className={`${
                        isVideoPlaying
                            ? PanelVideoLastEditionStyles.PanelVideoBoxPlaying
                            : PanelVideoLastEditionStyles.PanelVideoBox
                    } ${
                        isVideoMuted
                            ? PanelVideoLastEditionStyles.PanelVideoBoxMuted
                            : ""
                    }`}
                >
                    <div
                        className={
                            PanelVideoLastEditionStyles.PanelVideoPlaceholder
                        }
                    >
                        <img
                            onClick={onClickVideoItem}
                            className={
                                PanelVideoLastEditionStyles.PlaceholderImage
                            }
                            src={"/video-poster.jpg"}
                            alt="Grow 2018"
                        />
                    </div>
                    <div
                        className={`${
                            posterLoaded
                                ? PanelVideoLastEditionStyles.PanelVideoBoxInnerLoaded
                                : PanelVideoLastEditionStyles.PanelVideoBoxInner
                        }`}
                    >
                        <button
                            className={`${PanelVideoLastEditionStyles.PanelButton} ${PanelVideoLastEditionStyles.PanelButtonClose}`}
                            onClick={onClickVideoItem}
                        >
                            <Icon
                                name="close"
                                width={10}
                                height={10}
                                fill="#000"
                                extraClasses={
                                    PanelVideoLastEditionStyles.PanelButtonCloseIcon
                                }
                            />
                        </button>
                        <video
                            className={PanelVideoLastEditionStyles.PanelVideo}
                            ref={videoRef}
                            onClick={onClickVideoItem}
                            onTimeUpdate={onTimeUpdate}
                            poster={"/video-poster.jpg"}
                        >
                            <source
                                src={vimeo.srcset[2].link}
                                type="video/mp4"
                            />
                            <source
                                src={vimeo.srcset[0].link}
                                type="video/mp4"
                                media="all and (max-width: 1366px)"
                            />
                            <source
                                src={vimeo.srcset[3].link}
                                type="video/mp4"
                                media="all and (max-width: 960px)"
                            />
                            <source
                                src={vimeo.srcset[1].link}
                                type="video/mp4"
                                media="all and (max-width: 640px)"
                            />
                        </video>
                    </div>
                    <button
                        title={`${
                            isVideoPlaying ? "Mettre en pause" : "Jouer"
                        } la vidéo`}
                        className={`${PanelVideoLastEditionStyles.PanelButton} ${PanelVideoLastEditionStyles.PanelButtonPlay}`}
                        onClick={onClickVideoItem}
                    >
                        <Icon
                            name="play"
                            width={19}
                            height={25}
                            stroke="#000"
                            extraClasses={
                                PanelVideoLastEditionStyles.PanelButtonPlayIcon
                            }
                        />
                    </button>
                    <div className={PanelVideoLastEditionStyles.PanelBottom}>
                        <Row>
                            <Cell start="0" end="12">
                                <div
                                    className={
                                        PanelVideoLastEditionStyles.PanelBottomInner
                                    }
                                >
                                    <button
                                        className={`${PanelVideoLastEditionStyles.PanelButtonWord}`}
                                        onClick={onClickVideoItem}
                                    >
                                        <p
                                            className={`${PanelVideoLastEditionStyles.PanelButtonWordPlay}`}
                                        >
                                            Play
                                        </p>
                                        <p
                                            className={`${PanelVideoLastEditionStyles.PanelButtonWordPause}`}
                                        >
                                            Pause
                                        </p>
                                    </button>

                                    <div
                                        className={
                                            PanelVideoLastEditionStyles.Timeline
                                        }
                                        ref={timelineRef}
                                        onClick={onClickTimeline}
                                        onMouseOut={onMouseOut}
                                        onMouseMove={onMouseMove}
                                    >
                                        <span
                                            className={
                                                PanelVideoLastEditionStyles.TimelineProgress
                                            }
                                            style={{
                                                transform: `scaleX(${currentTimeScale})`,
                                            }}
                                        ></span>
                                        <span
                                            className={
                                                PanelVideoLastEditionStyles.TimelineIndicator
                                            }
                                            style={{
                                                transform: `scaleX(${
                                                    isHoveringTimeline
                                                        ? wantedTimeScale
                                                        : 0
                                                })`,
                                            }}
                                        ></span>
                                    </div>

                                    <button
                                        className={`${PanelVideoLastEditionStyles.PanelButtonWord}`}
                                        onClick={onClickVideoSound}
                                    >
                                        <p
                                            className={`${PanelVideoLastEditionStyles.PanelButtonWordOn}`}
                                        >
                                            Mute
                                        </p>
                                        <p
                                            className={`${PanelVideoLastEditionStyles.PanelButtonWordOff}`}
                                        >
                                            Unmute
                                        </p>
                                    </button>
                                </div>
                            </Cell>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    )
}

const query = graphql`
    {
        vimeo(link: { eq: "https://vimeo.com/322788701" }) {
            name
            duration
            link
            aspectRatio
            width
            height
            srcset {
                ...GatsbyVimeoSrcSet
            }
            pictures {
                uri
            }
            user {
                name
            }
        }
    }
`

export default PanelVideo
