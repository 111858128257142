import React, { useContext, useEffect } from "react"

import PanelGallery from "../components/Panels/PanelGallery"
import PanelVideoLastEdition from "../components/Panels/PanelVideoLastEdition"
import { TransitionState } from "gatsby-plugin-transition-link"
import { CustomContext } from "../context/CustomProvider"
import Footer from "../components/Layout/Footer"
import Metas from "../components/Misc/Metas"

const GalleryPage = () => {
    const { setIsHome } = useContext(CustomContext)

    useEffect(() => {
        setIsHome(false)
    }, [])

    return (
        <>
            <Metas title="Galerie Edition 2018" />
            <TransitionState>
                {({ transitionStatus, exit, enter }) => {
                    return (
                        <>
                            <div
                                className={`container container--gallery is-${transitionStatus}`}
                            >
                                <PanelVideoLastEdition />
                                <PanelGallery />
                                <Footer />
                            </div>
                        </>
                    )
                }}
            </TransitionState>
        </>
    )
}

export default GalleryPage
