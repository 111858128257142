import React, { useRef, useEffect, useContext, useState } from "react"
import { Row, Cell } from "../Grid"

import { useStaticQuery, graphql } from "gatsby"
import { CustomContext } from "../../../context/CustomProvider"

import FooterStyles from "./Footer.module.styl"

const Footer = () => {
    const queryResult = useStaticQuery(query)
    const { setBottomScrollTrigger } = useContext(CustomContext)
    const [windowSize, setWindowSize] = useState(0)
    const footerRef = useRef(null)
    const data = queryResult.footerJson.footer

    useEffect(() => {
        if (footerRef.current) {
            setBottomScrollTrigger(
                footerRef.current.getBoundingClientRect().top
            )
        }
    }, [footerRef, windowSize])

    const onResize = () => {
        setWindowSize(window.innerWidth)
    }

    useEffect(() => {
        onResize()
        window.addEventListener("resize", onResize)
        return () => {
            window.removeEventListener("resize", onResize)
        }
    }, [])

    return (
        <footer className={`${FooterStyles.Footer}`} ref={footerRef}>
            <div className={`${FooterStyles.FooterInner}`}>
                <Row>
                    <Cell start="0" end="4" tl={{ start: "1", end: "12" }}>
                        <div className={FooterStyles.FooterLeft}>
                            <h2 className={`heading-3`}>Producteur</h2>
                            <ul className={FooterStyles.FooterList}>
                                {data.organizers.map((item, index) => {
                                    return (
                                        <li
                                            key={`footer-organizers-${index}`}
                                            className={
                                                FooterStyles.FooterListItem
                                            }
                                        >
                                            <a
                                                className="link link--secondary"
                                                href={item.link.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                title={item.link.title}
                                            >
                                                <img
                                                    src={item.image.src}
                                                    alt={item.image.alt}
                                                />
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </Cell>
                    <Cell start="4" end="8" tl={{ start: "1", end: "12" }}>
                        <div className={FooterStyles.FooterMiddle}>
                            <h2 className={`heading-3`}>Creative designer</h2>
                            <ul className={FooterStyles.FooterList}>
                                {data.directors.map((item, index) => {
                                    return (
                                        <li
                                            key={`footer-directors-${index}`}
                                            className={
                                                FooterStyles.FooterListItem
                                            }
                                        >
                                            <a
                                                className="link link--secondary"
                                                href={item.link.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                title={item.link.title}
                                            >
                                                <img
                                                    src={item.image.src}
                                                    alt={item.image.alt}
                                                />
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </Cell>
                    <Cell start="8" end="12" tl={{ start: "1", end: "12" }}>
                        <div className={FooterStyles.FooterRight}>
                            <h2 className={`heading-3`}>Partenaires</h2>
                            <ul className={FooterStyles.FooterList}>
                                {data.sponsors.map((item, index) => {
                                    return (
                                        <li
                                            key={`footer-sponsors-${index}`}
                                            className={
                                                FooterStyles.FooterListItem
                                            }
                                        >
                                            <a
                                                className="link link--secondary"
                                                href={item.link.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                title={item.link.title}
                                            >
                                                <img
                                                    src={item.image.src}
                                                    alt={item.image.alt}
                                                />
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </Cell>
                </Row>
                <Row>
                    <Cell start="0" end="6">
                        <div className={FooterStyles.FooterBottom}>
                            <p className={`teasing-3`}>
                                Le développement créatif est une discipline en
                                expansion, qui se démocratise.
                            </p>
                            <p className={`teasing-3`}>
                                Les développeurs créatifs ont beaucoup
                                d’occasions de se retrouver à l’étranger et d’y
                                montrer leurs productions, mais encore trop peu
                                d’occasions en France, à commencer par Paris.
                                Nous avons donc créé GROW.
                            </p>
                            <p
                                className={`teasing-3 leader-8 ${FooterStyles.FooterMentions}`}
                            >
                                {" "}
                                <a
                                    href="https://www.grow.paris/2018"
                                    target="_blank"
                                    className={FooterStyles.FooterLink}
                                    rel="noopener nofollow noreferrer"
                                    title="Site de l'édition GROW 2018"
                                >
                                    <span
                                        className={FooterStyles.FooterLinkTitle}
                                    >
                                        Grow 2018
                                    </span>
                                </a>{" "}
                                <a
                                    href="https://www.grow.paris/2017"
                                    target="_blank"
                                    className={FooterStyles.FooterLink}
                                    rel="noopener nofollow noreferrer"
                                    title="Site de l'édition GROW 2017"
                                >
                                    <span
                                        className={FooterStyles.FooterLinkTitle}
                                    >
                                        Grow 2017
                                    </span>
                                </a>{" "}
                            </p>
                        </div>
                    </Cell>
                </Row>
                <Row>
                    <Cell start="0" end="6">
                        <div className={FooterStyles.FooterBottom}>
                            <span
                                className={`${FooterStyles.FooterCopyright} teasing-3`}
                            >
                                GROW&nbsp;2019
                            </span>
                            <p className={`teasing-3`}>
                                Gestion des données&nbsp;: nous utilisons{" "}
                                <a
                                    href="https://matomo.org/"
                                    target="_blank"
                                    className={FooterStyles.FooterLink}
                                    rel="noopener noreferrer"
                                    title="Site de Matomo"
                                >
                                    Matomo
                                </a>{" "}
                                pour recueillir des infos sur la fréquentation
                                du site.
                                <br />
                                Crédits&nbsp;: Les oeuvres présentées sont la
                                propriété de leurs artistes.
                                <br />
                                Les photos sont sans doute d'
                                <a
                                    href="http://edouardducos.fr/"
                                    target="_blank"
                                    className={FooterStyles.FooterLink}
                                    rel="noopener noreferrer"
                                    title="Site d'Édouard Ducos"
                                >
                                    Édouard Ducos
                                </a>
                            </p>
                            <p className={`teasing-3`}>
                                Design et identité&nbsp;:{" "}
                                <a
                                    href="https://www.spintank.fr/"
                                    target="_blank"
                                    className={FooterStyles.FooterLink}
                                    rel="noopener noreferrer"
                                    title="Site de Spintank"
                                >
                                    Spintank.fr
                                </a>
                            </p>
                        </div>
                    </Cell>
                </Row>
            </div>
        </footer>
    )
}

const query = graphql`
    query FooterQuery {
        footerJson {
            footer {
                organizers {
                    link {
                        url
                        title
                    }
                    image {
                        src
                        alt
                    }
                }
                sponsors {
                    link {
                        url
                        title
                    }
                    image {
                        src
                        alt
                    }
                }
                directors {
                    link {
                        url
                        title
                    }
                    image {
                        src
                        alt
                    }
                }
            }
        }
    }
`

export default Footer
