import React from "react"
import Img from "gatsby-image"

import { useStaticQuery, graphql } from "gatsby"

import PanelGalleryStyles from "./PanelGallery.module.styl"

const PanelGallery = () => {
    const data = useStaticQuery(query)

    const renderGrid = () => {
        return data.panelGalleryJson.panelGallery.images.map((grid, index) => {
            return (
                <div
                    className={PanelGalleryStyles.PanelRow}
                    key={`gallery-row-${index}`}
                >
                    {Object.keys(grid).map((columnKey, columnIndex) => {
                        if (grid[columnKey]) {
                            return (
                                <div
                                    className={`${
                                        PanelGalleryStyles.PanelColumn
                                    } ${
                                        columnKey === "full"
                                            ? PanelGalleryStyles.PanelColumnFull
                                            : ""
                                    }`}
                                    key={`column-${columnKey}-${columnIndex}`}
                                >
                                    {grid[columnKey].map(
                                        (image, imageIndex) => {
                                            let currentImage = null

                                            for (
                                                let i = 0,
                                                    j =
                                                        data.allFile.edges
                                                            .length;
                                                i < j;
                                                i++
                                            ) {
                                                const currentFile =
                                                    data.allFile.edges[i].node

                                                if (
                                                    currentFile.childImageSharp
                                                        .fluid.originalName ===
                                                    image.filename
                                                ) {
                                                    currentImage =
                                                        currentFile.childImageSharp
                                                    break
                                                }
                                            }

                                            return (
                                                <div
                                                    className={`${
                                                        PanelGalleryStyles.PanelBoxImage
                                                    } ${getImageRatio(
                                                        image.type
                                                    )} ${
                                                        image.size === "full"
                                                            ? PanelGalleryStyles.PanelBoxImageFull
                                                            : ""
                                                    }`}
                                                    key={`gallery-box-image-${imageIndex}`}
                                                >
                                                    <Img
                                                        fluid={
                                                            currentImage.fluid
                                                        }
                                                        alt={image.title}
                                                    />
                                                </div>
                                            )
                                        }
                                    )}
                                </div>
                            )
                        } else {
                            return null
                        }
                    })}
                </div>
            )
        })
    }

    const getImageRatio = type => {
        let ratio = null
        switch (type) {
            case "portrait":
                ratio = PanelGalleryStyles.PanelBoxImagePortrait
                break
            case "full":
                ratio = PanelGalleryStyles.PanelBoxImageFull
                break
            case "square":
                ratio = PanelGalleryStyles.PanelBoxImageSquare
                break
            default:
                ratio = ""
        }
        return ratio
    }

    return (
        <div className={PanelGalleryStyles.PanelGallery}>
            <div className={PanelGalleryStyles.PanelInner}>{renderGrid()}</div>
        </div>
    )
}

const query = graphql`
    query MyQuery {
        allFile(
            filter: {
                extension: { eq: "jpg" }
                relativeDirectory: { eq: "assets/images/last-edition" }
            }
            sort: { fields: name }
        ) {
            edges {
                node {
                    childImageSharp {
                        fluid(quality: 100, maxWidth: 1440) {
                            aspectRatio
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                            sizes
                            originalImg
                            originalName
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
            }
        }
        panelGalleryJson {
            panelGallery {
                images {
                    column1 {
                        filename
                        size
                        title
                        type
                        url
                    }
                    column2 {
                        url
                        type
                        title
                        size
                        filename
                    }
                    full {
                        filename
                        size
                        title
                        type
                        url
                    }
                }
            }
        }
    }
`
export default PanelGallery
